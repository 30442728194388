<template>
  <div>
    <b-modal
      :no-close-on-backdrop="true"
      ref="save-modal"
      hide-footer
      title="Kitchen Credential"
    >
      <div
        v-if="alert || apiResponceError"
        role="alert"
        aria-live="polite"
        aria-atomic="true"
        :class="changeClasss ? 'alert alert-success' : 'alert alert-danger'"
      >
        {{ alertText }}
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group id="input-group-2" label="Email:" label-for="input-2">
            <b-form-input
              required=""
              id="email"
              @keyup="isEmailValid"
              placeholder="Enter Email"
              v-model="KitchenEmail"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <b-form-group
            id="input-group-2"
            label="User Name:"
            label-for="input-2"
          >
            <b-form-input
              required=""
              id="phone"
              v-model="KitchenUserName"
              placeholder="Enter User Name"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <b-form-group
            id="input-group-2"
            label="Password *"
            label-for="input-2"
          >
            <b-form-input
              v-model="password"
              type="password"
              placeholder="Enter Password"
            ></b-form-input>
          </b-form-group>
        </div>
      </div>

      <div style="float: right">
        <a
          @click="saveRecord()"
          ref="kt_login_signin_submit"
          type="button"
          class="btn btn-success mr-3"
        >
          save
        </a>
        <a type="button" class="btn btn-secondary" @click="hideModal">
          No
        </a>
      </div>
    </b-modal>
  </div>
</template>
<script>
import ApiService from "@/core/services/api.service";
export default {
  props: {
    email: {
      type: String,
    },
    username: {
      type: String,
    },
    kitcheUserId: {
      type: Number,
    },
    restaurantId: {
      type: Number,
    },
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      showKitchenDetailModal: this.show,
      modalBodyText: "",
      KitchenUserName: "",
      KitchenEmail: "",
      password: "",
      alert: false,
      alertText: "",
      changeClasss: false,
      apiResponceError: false,
      emailValidation: false,
    };
  },

  mounted() {
    this.$refs["save-modal"].show();
    this.KitchenEmail = this.email;
    this.KitchenUserName = this.username;
    this.showModel = true;
  },

  methods: {
    isEmailValid: function isEmailValid() {
      this.apiResponceError = false;
      this.emailValidation = false;
      var regex = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
      var responce = regex.test(this.KitchenEmail);
      if (responce) {
        this.alert = false;
      } else {
        this.alertText = "Please enter valid email address";
        this.changeClasss = false;
        this.alert = true;
        this.emailValidation = true;
      }
      return responce;
    },
    hideModal() {
      this.showModel = false;
      this.$refs["save-modal"].hide();
      this.$emit("closeModal", false);
    },
    validationMessage(message) {
      this.alert = true;
      this.alertText = message;
      this.changeClasss = false;
    },

    saveRecord() {
      try {
        if (this.password.length > 0 && this.password.length < 6) {
          this.validationMessage("Password must be at least 6 characters long");
          return false;
        }
        if (this.KitchenEmail === "") {
          this.validationMessage("Please select email");
          return false;
        }
        if (this.KitchenUserName === "") {
          this.validationMessage("Please select username");
          return false;
        }
        if (this.emailValidation) {
          return false;
        }
        const submitButton = this.$refs["kt_login_signin_submit"];
        submitButton.classList.add("spinner", "spinner-light", "spinner-right");
        const data = {
          email: this.KitchenEmail,
          username: this.KitchenUserName,
          id: this.kitcheUserId,
          restaurant_id: this.restaurantId,
          password: this.password,
        };
        // const headers = {
        //   Authorization: "Bearer " + localStorage.getItem("token"),
        // };
        ApiService.post(this.$path + "/register-kitchen", data, {
          // headers,
        }).then((response) => {
          if (response.data.code == 422) {
            this.alert = true;
            this.alertText = response.data.message;
            const submitButton = this.$refs["kt_login_signin_submit"];
            submitButton.classList.remove(
              "spinner",
              "spinner-light",
              "spinner-right"
            );
            return false;
          } else {
            this.$emit("closeModal", false);
            this.$emit("ChangeKitchenCredential", response.data);
          }
        });
      } catch (err) {
        if (!err.response) {
          alert("Please Check You Internet Connection");
        }
      }
    },

    commonAction() {
      const submitButton = this.$refs["kt_login_signin_submit"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");
      if (this.actionType == "delete") {
        this.deleteRecord();
      }
      if (this.actionType == "delete-resturant-image") {
        this.deleteRestaurantImage();
      }
      if (this.actionType == "delete-delivery-address") {
        this.deleteDeliveryAddress();
      }
      if (this.actionType == "active" || this.actionType == "deactive") {
        this.changeStatus();
      }
    },
  },
};
</script>

<style scoped>
.btn-success {
  border-color: #5cb700;
  background-color: rgb(92, 183, 0);
}
.btn.btn-success:hover {
  border-color: #5cb700 !important;
  background-color: rgb(92, 183, 0) !important;
}
</style>
